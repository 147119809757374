import { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  FormControl,
  Grid,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { createPushService, updatePushService } from "services/nodejs.service";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
// import { createPushService } from "services/nodejs.service.ts";

const outputMap = [
    {id:1, title:"1 vez a la semana"},
    {id:2, title:"1 ves cada 2 semanas"},
    {id:3, title:"cada inicio de mes"},
    {id:4, title:"al fin de mes"},
];

const exampleX= [
    {id:1, title:"pushes botnar"},
    {id:2, title:"pushes navideños"},
    {id:3, title:"pushes normales"},
    {id:4, title:"pushes edades"},
]

interface LanguageData {
  on: boolean;
  id?: number | null;
  media: { id: number } | null;
  templete?: { id: number } | null;
  format?: number | null;
  //   channel: 'APP BRAZIL'| 'WHATSAPP BRAZIL' | 'APP BOTNAR' | 'WHATSAPP BOTNAR' | 'APP POR PROGRAMA' | 'WHATSAPP PROGRAMA' | 'AFINIAPP' | ''
}

export interface LanguageMap {
  spanish: LanguageData;
  english: LanguageData;
  portuges: LanguageData;
}

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type Props = {
  close: () => void;
  visible: boolean;
  englishTemplete: any[];
  spanishTemplete: any[];
  portTemplete: any[];
  imageMedia: any[];
  videoMedia: any[];
  refeshPush: () => void;
  toEdit: any;
};

const languageTranslationMap: any = {
  es: "spanish",
  en: "english",
  pt: "portuges",
};

const templeteLanguaheTranslationMap: any = [
  "",
  "spanish",
  "english",
  "",
  "portuges",
];

function isEmpty(obj: any) {
  if (obj.reset) return true;
  return Object.keys(obj).length === 0;
}

function SinglePushModal({
  close,
  visible,
  englishTemplete,
  spanishTemplete,
  portTemplete,
  imageMedia,
  videoMedia,
  refeshPush,
  toEdit,
}: Props) {
  const [sendDate, setSendDate] = useState<Dayjs | null>(dayjs());
  const [ageReq, setAgeReq] = useState(false);
  const [minorModal, setMinorModal] = useState(false);
  const [ageMin, setAgeMin] = useState<undefined | number>();
  const [ageMax, setAgeMax] = useState<undefined | number>();
  const [output, setOutput] = useState<undefined | number>(0);
  const [channel, setChannel] = useState<undefined | number>(0);
  const [programs, setPrograms] = useState("");
  const [languageMap, setLanguageMap] = useState<LanguageMap>({
    spanish: { on: false, media: null, format: 0, templete: null, id: null },
    english: { on: false, media: null, format: 0, templete: null, id: null },
    portuges: { on: false, media: null, format: 0, templete: null, id: null },
  });
  const resetLanguageMap = {
    spanish: { on: false, media: null, format: 0, templete: null, id: null },
    english: { on: false, media: null, format: 0, templete: null, id: null },
    portuges: { on: false, media: null, format: 0, templete: null, id: null },
  } as LanguageMap;
  const refreshModal = () => {
    setOutput(0);
    setChannel(0);
    setPrograms("");
    setLanguageMap(resetLanguageMap);
    setAgeReq(false);
    setAgeMin(undefined);
    setAgeMax(undefined);
  };
  
  useEffect(() => {
    if (!Object.keys(toEdit).length) {
      return;
    }
    if (toEdit.reset) {
      refreshModal();
      return;
    }
    console.log("did not exit");
    setSendDate(
      dayjs(
        new Date(
          toEdit.date_to_send.getTime() +
            toEdit.date_to_send.getTimezoneOffset() * 60000
        )
      )
    );
    if (toEdit.age_min && toEdit.age_max) {
      setAgeReq(true);
      setAgeMax(toEdit.age_max);
      setAgeMin(toEdit.age_min);
    } else {
      setAgeReq(false);
      setAgeMax(undefined);
      setAgeMin(undefined);
    }
    setOutput(toEdit.output);
    setChannel(toEdit.channel);
    setPrograms(toEdit.program_ids);
    let tempLanguageMap: any = resetLanguageMap;
    Object.keys(toEdit.post_ids).forEach((element) => {
      tempLanguageMap[languageTranslationMap[element]] = {
        on: true,
        id: toEdit.post_ids[element],
      };
    });
    if (toEdit.channel < 100) {
      Object.keys(toEdit.messages).forEach((element) => {
        tempLanguageMap[languageTranslationMap[element]].on = true;
        tempLanguageMap[languageTranslationMap[element]].templete =
          toEdit.messages[element].id;
      });
    } else {
      const keyMap = [[], spanishTemplete, englishTemplete, [], portTemplete];
      toEdit.templete?.forEach((element: any) => {
        console.log('element', element)
        console.log('keyMap[element.lang]', keyMap[element.lang])
        let selected = keyMap[element.lang].find((el: any) => el.label == element.name);
        let object = imageMedia
          .concat(videoMedia)
          .find((el) => el.id == element.object_id);
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].on = true;
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].templete =
          selected;
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].format =
          element.type?element.type:0;
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].media =
          object;
      });
    }
    console.log("to edit", toEdit);
    setLanguageMap(tempLanguageMap);
  }, [toEdit]);
  useEffect(() => {
    if (!languageMap.english.on) {
      setLanguageMap((prevState) => ({
        ...prevState,
        ["english"]: { on: false, media: null, format: 0 },
      }));
    }
    if (!languageMap.spanish.on) {
      setLanguageMap((prevState) => ({
        ...prevState,
        ["spanish"]: { on: false, media: null, format: 0 },
      }));
    }
    if (!languageMap.portuges.on) {
      setLanguageMap((prevState) => ({
        ...prevState,
        ["portuges"]: { on: false, media: null, format: 0 },
      }));
    }
  }, [languageMap.english.on, languageMap.spanish.on, languageMap.portuges.on]);

  async function createPush() {
    let pushToSend: any = {
      channel: channel,
      date_to_send: sendDate?.format("DD MMM YYYY"),
      output: output,
      program_ids: programs,
    };
    let templetes: any = {};
    const assignTemplate = (lang: string, templateData: any) => {
        templetes[lang] = {
          id: templateData.templete?.id,
          media: templateData.media?.id,
        };
      };
      
      const checkAndAssignTemplate = (lang: string, templateData: any) => {
        if (!templateData.templete) {
          setMinorModal(true);
          return false;
        }
        templetes[lang] = { id: templateData.templete };
        return true;
      };
      
      if (channel && channel > 99) {
        if (languageMap.spanish.on) assignTemplate('es', languageMap.spanish);
        if (languageMap.english.on) assignTemplate('en', languageMap.english);
        if (languageMap.portuges.on) assignTemplate('pt', languageMap.portuges);
      } else {
        if (languageMap.spanish.on && !checkAndAssignTemplate('es', languageMap.spanish)) return;
        if (languageMap.english.on && !checkAndAssignTemplate('en', languageMap.english)) return;
        if (languageMap.portuges.on && !checkAndAssignTemplate('pt', languageMap.portuges)) return;
      }
      
    
    pushToSend.templetes = JSON.stringify(templetes);
    let post_ids: any = {};
    if (languageMap.spanish.id) post_ids.es = languageMap.spanish.id;
    if (languageMap.english.id) post_ids.en = languageMap.english.id;
    if (languageMap.portuges.id) post_ids.pt = languageMap.portuges.id;
    pushToSend.post_ids = JSON.stringify(post_ids);
    if (ageReq) {
      pushToSend.age_max = "" + ageMax;
      pushToSend.age_min = "" + ageMin;
    }
    let push;
    if (isEmpty(toEdit)) {
      push = await createPushService(pushToSend);
    } else {
      pushToSend.id = toEdit.id;
      push = await updatePushService(pushToSend);
    }
    if (push.status == 201) {
      sleep(1000);
      refeshPush();
      refreshModal();
      close();
    }
  }

  console.log("state of language map", sendDate);
  return (
    <Modal open={visible}>
      <form>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid
              container
              spacing={1 / 2}
              style={{ display: "flex", flexDirection: "row" }}
              p={1}
            >
             <Grid item xs={6}>
                {/*<TextField
                    label="Frecuencia"
                    value={programs}
                    onChange={(el) => setPrograms(el.target.value)}
                    fullWidth
                /> */}
                <Autocomplete
                    id=""
                    options={outputMap}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Frecuencia de envio"
                        placeholder=""
                    />
                    )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="channel">Channel</InputLabel>
                  <Select
                    labelId="channel"
                    id="channel"
                    label="Channel"
                    value={channel}
                    onChange={(v) => {
                      setChannel(v.target.value as number);
                      setLanguageMap(resetLanguageMap);
                      setPrograms("");
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      APP POR PROGRAMA
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      APP BRAZIL
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      APP BOTNAR
                    </MenuItem>
                    <MenuItem key={3} value={50}>
                      AFINIAPP
                    </MenuItem>
                    <MenuItem key={4} value={100}>
                      WHATSAPP PROGRAMA
                    </MenuItem>
                    <MenuItem key={5} value={101}>
                      WHATSAPP BRAZIL
                    </MenuItem>
                    <MenuItem key={6} value={102}>
                      WHATSAPP BOTNAR
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {channel === 100 || channel === 0 && (
                <Grid item xs={12} p={1}>
                <TextField
                    label="Program ids"
                    value={programs}
                    onChange={(el) => setPrograms(el.target.value)}
                    fullWidth
                />
                </Grid>
            )}
            
          </Grid>
            <Grid item xs={12} p={1}>
            <Autocomplete
                    multiple
                    id=""
                    options={exampleX}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Pushes a enviar"
                        placeholder=""
                    />
                    )}
                />
            </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={createPush}>
                {isEmpty(toEdit) ? "Agregar" : "Editar"}
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  refreshModal();
                  close();
                }}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Modal open={minorModal}>
          <Box sx={minorStyle}>
            <Grid container flexDirection={"row"}>
              <Grid container p={1}>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    Error al guardar push.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    onClick={() => setMinorModal(false)}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </form>
    </Modal>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  minWidth: "70%",
};

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export default SinglePushModal;
