export const MENU_ADMIN: any = [
    {
        id: 1,
        name: 'Bots',
        subtitle: 'BOTS:',
        icon: 'fas fa-fw fa-robot',
        items: [
            { id: 1, name: 'Listado de bots', path: '/admin/bots/' },
            { id: 2, name: 'Crear nuevo', path: '/admin/bots/add' },
        ]
    },
    {
        id: 2,
        name: 'Entities',
        subtitle: 'ENTITIES',
        icon: 'fas fa-archway',
        items: [
            { id: 1, name: 'Crear entidad', path: '/admin/entity/add' },
            { id: 2, name: 'Listar entidades', path: '/admin/entity/list' },
        ]
    },
    {
        id: 3,
        name: 'Attributes',
        subtitle: 'Attributes',
        icon: 'fas fa-list-alt',
        items: [
            { id: 1, name: 'Crear atributo', path: '/admin/attributes/add' },
            { id: 2, name: 'Listar atributos', path: '/admin/attributes/list' },
        ]
    },
    {
        id: 4,
        name: 'Programs',
        subtitle: 'PROGRAMS:',
        icon: 'fa fa-list',
        items: [
            { id: 1, name: 'Riesgos de programas', path: '/admin/programs' },
            { id: 1, name: 'Reportes de programas', path: '/admin/programs/report' },
        ]
    },
    {
        id: 5,
        name: 'Hot triggers',
        subtitle: 'HOT TRIGGERS:',
        icon: 'fa fa-bolt',
        items: [
            { id: 1, name: 'Listado de secuencias', path: '/admin/sequences' },
        ]
    },
    {
        id: 6,
        name: 'Usuarios del Bot',
        subtitle: 'USUARIOS DEL BOT',
        icon: 'fas fa-fw fa-users',
        items: [
            { id: 1, name: 'Groups', path: '/admin/user-groups' },
        ]
    },
    {
        id: 7,
        name: 'Live chat',
        subtitle: 'LIVE CHAT',
        icon: 'fa fa-comments',
        items: [
            { id: 1, name: 'Live chat', path: '/admin/live-chat/bots' },
        ]
    },
    {
        id: 8,
        name: 'People',
        subtitle: 'PEOPLE',
        icon: 'fa fa-users',
        items: [
            { id: 1, name: 'People', path: '/admin/people' },
        ]
    },
    {
        id: 9,
        name: 'NLU',
        subtitle: 'NLU',
        icon: 'far fa-smile',
        items: [
            { id: 1, name: 'NLU', path: '/admin/nlu' },
            { id: 2, name: 'Quick replies', path: '/admin/quick-replies' },
            { id: 3, name: 'Retrain', path: '/admin/retrain' },
        ]
    },
    {
        id: 10,
        name: 'Milestones',
        subtitle: 'Milestones',
        icon: 'fa fa-lightbulb',
        items: [
            { id: 1, name: 'Programas', path: '/admin/milestones/programas' },
            { id: 2, name: 'Listado de hitos', path: '/admin/milestones/listado-hitos' },
        ]
    },
    {
        id: 11,
        name: 'Posts',
        subtitle: 'Posts',
        icon: 'fa fa-lightbulb',
        items: [
            { id: 1, name: 'Listado de Traducciones', path: '/admin/posts/translations' }
        ]
    },
    {
        id: 12,
        name: 'Articles',
        subtitle: 'Articles',
        icon: 'fa fa-newspaper',
        items: [
            { id: 1, name: 'Listado de Traducciones', path: '/admin/articles/traductions' },
            { id: 2, name: 'Listado de Articulos', path: '/admin/articles/list' },
        ]
    },
    {
        id: 13,
        name: 'Partners',
        subtitle: 'Partners',
        icon: 'fa fa-handshake',
        items: [
            { id: 1, name: 'grupos', path: '/admin/partners/groups' }
        ]
    },
    {
        id: 14,
        name: 'Sessions',
        subtitle: 'Sessions',
        icon: 'fa fa-comment-alt',
        items: [
            { id: 1, name: 'lista de sesiones', path: '/admin/sessions/' }
        ]
    },
    {
        id: 15,
        name: 'Push',
        subtitle: 'Push Notifications',
        icon: 'fa fa-comment-alt',
        items: [
            { id: 1, name: 'Push Notifications', path: '/admin/push/' },
            { id: 1, name: 'Push By Calendar', path: '/admin/push/calendar' },
            { id: 1, name: 'Push By Event', path: '/admin/push/event' },
            { id: 2, name: 'Templetes', path: '/admin/push/templete' },
            { id: 3, name: 'Objects', path: '/admin/push/objects' },
            { id: 4, name: 'Object Post', path: '/admin/objects/posts' }
        ]
    },
    {
        id: 16,
        name: 'Queries',
        subtitle: 'Queries',
        icon: 'fa fa-comment-alt',
        items: [
            { id: 1, name: 'Tiempo en WhatsApp', path: '/admin/queries/' }
        ]
    },
]

export const MENU_PARTNERS: any = [
    {
        id: 1,
        name: 'Partners',
        subtitle: 'Partners',
        icon: 'fa fa-handshake',
        items: [
            { id: 1, name: 'grupos', path: '/admin/partners/groups' }
        ]
    }
]

